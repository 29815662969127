import React from 'react'
import Layout from 'layouts/en'
import styled from 'styled-components'
import design from 'design'
import { Title, Text, Row, Div, Box, Button } from 'components'
import { TryOrSchedule } from 'containers/en'
import YouTube from 'react-youtube-embed'
import { getYoutubeId } from 'utils'

const Video = ({ className, youtube }) => {
  const id = getYoutubeId(youtube)
  return (
    <Div className={'video'} mx={[-36,0]}>
      <YouTube id={id} />
    </Div>
  )
}

export default () => {
  return (
    <Layout p={5}>
      <Div pt={[6,8]} px={[0,6]} textAlign={['center', 'left']}>
        <Title mb={5} fontSize={[7,9]}>Master the Stairlin basics in 10 minutes</Title>
        <Text mb={8} fontSize={[6,8]}>This short video will make you a pro in no time</Text>

        <Div tag={'article'} mb={[6,8]} display={'flex'} flexDirection={['column-reverse','column']}>
          <Div textAlign={'left'} py={[4,0]} px={[5,0]} mx={[-36,0]} bg={['white','transparent']} boxShadow={['black','none']}>
            <Title mb={[3,4]} fontSize={[3,7]}>Setup your business</Title>
            <Text mb={[0,6]} fontSize={[2,5]}>In this video we will show you how you can setup a fully functional business on Stairlin in 10 minutes.</Text>
          </Div>
          <Video youtube={'https://www.youtube.com/watch?v=zQxMhoKEfzk'} />
        </Div>

        <Div mt={8}>
          <TryOrSchedule />
        </Div>
      </Div>
    </Layout>
  )
}
